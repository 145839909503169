<template>
  <div id="app">
  </div>
</template>


<style scoped>
</style>

/*
* 获取微信传过来的code
* 登陆，获取accessToken
* 如果未绑定过消费者，则跳转到身份选择
* 如果已绑定过，则跳到首页或者微信传过来的state
*/

<script>
import Vue from 'vue'
import {appProd, loginParam, appId} from '@/config/env.dev'
import {post} from '@/utils/http'
import Notify from "vant/es/notify";
import {ACCESS_TOKEN, ACCESS_Customer, CURRENT_BUSINESS_MODULE} from "@/store/mutation-types";

export default {
  data() {
    return {};
  },
  mounted() {
    if (Vue.ls.get(ACCESS_TOKEN)) {
      Vue.ls.set(ACCESS_TOKEN, null)
      Vue.ls.set(ACCESS_Customer, null)
    }

    var query = this.$route.query;
    if (!query.code) {
      var domine = window.location.href.split('#')[0] // 微信会自动识别#    并且清除#后面的内
      var url = encodeURI(domine)

      if (appProd === 'dev') {
        url = 'http://xuexiao.fsytss.com/testLogin';
      } else if (appProd === 'dev-zsy') {
        url = 'http://test-mobile.zhtc.zhongshanyun.cn/testLogin';
      }

      let jumpUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
          appId +
          '&redirect_uri=' +
          url +
          '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
      window.location.href = jumpUrl
      return;
    }
    var code = query.code;
    this.grant(code);
  },
  methods: {
    grant(code) {
      loginParam.credential = code;
      loginParam.username = 'wechat';
      post('api/authorize/v1/grant', loginParam, false).then(res => {
        Vue.ls.set(ACCESS_Customer, res.data.customer, 7 * 24 * 60 * 60 * 1000)
        Vue.ls.set(ACCESS_TOKEN, res.data.accessToken, 7 * 24 * 60 * 60 * 1000)
        Vue.ls.set(CURRENT_BUSINESS_MODULE, 'school');
        if (res.data.customer) {
          this.$router.push({path: '/index'});
        } else {
          this.$router.push({path: '/switch'});
        }
      }).catch(error => {
        Notify({type: 'warning', message: error.message});
      })
    }
  },
  computed: {}
};
</script>
